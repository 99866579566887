<template>
  <div class="page-add-wallet page-content">
    <KlubbaRichText class="texts">
      <p><b>{{ `Welcome back, ${ $store.getters.getUserKey('first_name') ?? 'User'}!` }}</b></p>
      <p>{{ progress.description }}</p>
    </KlubbaRichText>

    <div class="card-wrap">
      <klubba-product-card
        v-if="subscription"
        :product="subscription"
        class="klubba-product-card"
        :showClub="true"
      />
    </div>

    <div class="button-wrap">
      <klubba-footer
        backButton="Sign Out"
        @goPrevPage="prevPage"
        @goNextPage="nextPage"
      />
    </div>
  </div>
</template>

<script>
import firebaseApp from '@/config/firebase';
import KlubbaProductCard from '@/components/custom/KlubbaProductCard.vue';
import KlubbaFooter from '@/components/layout/KlubbaFooter.vue';
import KlubbaRichText from '@/components/default/KlubbaRichText.vue';

export default {
  components: {
    KlubbaRichText,
    KlubbaProductCard,
    KlubbaFooter,
  },
  data() {
    return {
      progress: {
        title: 'Welcome back!',
        description: 'Add the following subscription to your Klubba wallet?',
      },
    };
  },
  mounted() {
    if (!this.subscription) {
      this.$router.push(`/${this.$route.params.id}/product-details`);
    }
  },
  computed: {
    clubSpecific() {
      return this.$store.getters.clubSpecific;
    },
    club() {
      return this.$store.getters.club;
    },
    subscription() {
      return this.$store.getters.selectedClubSubscription;
    },
  },
  methods: {
    async prevPage() {
      await firebaseApp.auth().signOut().then(() => {
        this.$router.push(`/${this.club.id}/request-code`);
      });
    },
    nextPage() {
      this.$router.push(`/${this.club.id}/start-date`);
    },
  },
};
</script>

<style lang="sass" scoped>
.texts
  @apply p-8

.card-wrap
  @apply mx-8 relative

.button-wrap
  @apply p-8 mt-auto

.klubba-product-card
  @apply -mx-1
</style>
